import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  SAVE_MY_TEAM_DATA,
  SAVE_ROLES_LIST,
  SAVE_TEAM_DATA,
  SET_LOADING_TEAM,
} from "./constants";
import {
  roleInterface,
  teamInterface,
  teamMemberWrapper,
} from "../../interfaces/teamInterfaces";
import teamsService from "../../services/Teams.service";
import organizationService from "../../services/Organization.service";
import { saveOrganizationData } from "../organization/actions";
import { ROLE_BUYER, ROLE_TEAM_LEADER } from "../../constants/constants";

export const fetchTeamData = createAsyncThunk(
  "FETCH_TEAM_DATA",
  async (teamMemberIds: any, thunkApi) => {
    const teamData = await teamsService.getTeams();
    thunkApi.dispatch(
      SaveTeamData({ teams: teamData, memberId: teamMemberIds })
    );
    thunkApi.dispatch(setLoadingTeam(false));

    return teamData;
  }
);

export const fetchOrganizationData = createAsyncThunk(
  "FETCH_ORGANIZATION_DATA",
  async (_, thunkApi) => {
    const state: any = thunkApi.getState();
    const { userLogged } = state.userState;
    const organizationData = await organizationService.getOrganization();
    const roles = await teamsService.getRoles();
    thunkApi.dispatch(saveRolesList(roles));
    thunkApi.dispatch(saveOrganizationData(organizationData));

    return {
      isOwner:
        userLogged.toString() ===
        organizationData[0].relationships.account_owner.data.id,
    };
  }
);

export const SaveTeamData = createAction(SAVE_TEAM_DATA, (payload: any) => {
  let myDataTeam: any = undefined;
  let myTeam: teamMemberWrapper[] = [];

  if (payload.teams.length > 0) {
    payload.teams.forEach((team: teamInterface) => {
      if (
        team.attributes.members.find((a: teamMemberWrapper) =>
          payload.memberId.includes(a.id)
        )
      ) {
        myDataTeam = team.attributes.members.find((a: teamMemberWrapper) =>
          payload.memberId.includes(a.id)
        );
        if (myDataTeam) {
          myDataTeam.team_name = team.attributes.team_name;
          myDataTeam.organization_name = team.attributes.organisation_name;
        }
        myTeam = team.attributes.members;
      }
    });
  }
  return {
    payload: {
      team: payload.teams,
      myDataTeam,
      myTeam,
      isLeader: myDataTeam.role.id === ROLE_TEAM_LEADER,
      isBuyer: myDataTeam.role.id === ROLE_BUYER,
      notInTeam: !myDataTeam,
    },
  };
});

export const saveMyTeamData = createAction(
  SAVE_MY_TEAM_DATA,
  (payload: teamInterface[]) => ({
    payload: payload,
  })
);

export const saveRolesList = createAction(
  SAVE_ROLES_LIST,
  (payload: roleInterface[]) => ({
    payload: payload,
  })
);

export const setLoadingTeam = createAction(
  SET_LOADING_TEAM,
  (payload: boolean) => ({
    payload: payload,
  })
);

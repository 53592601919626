import moment from "moment";
import { weeksInterface } from "../interfaces/calendarInterfaces";
import { dimensionsScoreInterface } from "../interfaces/scoresInterfaces";

export const getAllUserWeeks = (monthSelected: any, userRegisterDate: any) => {
  const userStartDate = moment(userRegisterDate);

  let weeks = [];

  if (monthSelected.month() < moment(userStartDate).month()) {
    for (
      let i = 0;
      moment(monthSelected)
        .endOf("month")
        .isAfter(moment(moment(monthSelected.startOf("month")).add(i, "w")));
      i++
    ) {
      const week = {
        start: moment(monthSelected.startOf("month"))
          .add(i, "w")
          .startOf("isoWeek"),
        end: moment(monthSelected.startOf("month"))
          .add(i, "w")
          .endOf("isoWeek"),
      };
      weeks.push(week);
    }
  } else {
    for (
      let i = 0;
      moment()
        .endOf("month")
        .isAfter(moment(moment(userStartDate).add(i, "w")));
      i++
    ) {
      const week = {
        start: moment(userStartDate).add(i, "w").startOf("isoWeek"),
        end: moment(userStartDate).add(i, "w").endOf("isoWeek"),
      };
      weeks.push(week);
    }
  }

  const oddWeeks = weeks.filter((el: weeksInterface, i: number) => i % 2 === 0);

  const month = moment(monthSelected).format('MMM')
  const monthWeeks = oddWeeks.filter((el) => el.start.format('MMM') === month || el.end.format('MMM') === month)
  return monthWeeks;
};


export const orderDimmensions = (dimmensions: any) => 
  dimmensions.sort(
    (a: dimensionsScoreInterface, b: dimensionsScoreInterface) => {
      if (a.dimmension > b.dimmension) {
        return 1;
      }
      if (a.dimmension < b.dimmension) {
        return -1;
      }
      return 0;
    }
  );

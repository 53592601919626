import { createReducer } from "@reduxjs/toolkit";
import { userLoggout } from "../user/actions";
import { openModal, closeModal } from "./actions";

interface ModalState {
  modalOpen: string;
}

const modalState = {
  modalOpen: "",
} as ModalState;

export const modalReducer = createReducer(modalState, (builder) =>
  builder
    .addCase(openModal, (state, action) => {
      state.modalOpen = action.payload;
    })
    .addCase(closeModal, (state, action) => {
      state.modalOpen = "";
    })
    .addCase(userLoggout.fulfilled, (state, action) => {
      state = modalState;
    })
);

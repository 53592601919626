import { createReducer } from "@reduxjs/toolkit";
import {
  saveUserData,
  saveUserLoginData,
  changeUserBlocked,
  saveMyUserProfile,
  userLoggout,
  checkAuthenticatedUser,
  authenticateUser,
} from "./actions";
import { UserState } from "./types";

const userState = {
  user: null,
  userLogged: null,
  userRoles: [],
  userIds: [],
  userTeamMemberIds: [],
  orgLicenseType: null,
  userBlocked: false,
  accessChat: false,
  profile: null,
  userUuid: null,
  checkingAuthentication: true,
  fetchingUser: true,
  isAuthenticated: false,
  authenticatingUser: false,
  authenticateUserError: "",
} as UserState;

export const userReducer = createReducer(userState, (builder) =>
  builder
    .addCase(saveUserData, (state, action) => {
      state.user = action.payload;
    })
    .addCase(saveUserLoginData, (state, action) => {
      state.userLogged = action.payload.user_id;
      state.userRoles = action.payload.roles;
      state.userIds = action.payload.member_ids;
      state.userTeamMemberIds = action.payload.team_member_ids;
      state.orgLicenseType = action.payload.licence_type;
      state.accessChat = action.payload.access_chat;
      state.userUuid = action.payload.user_uuid;
      state.user = action.payload;
      state.isAuthenticated = true;
    })
    .addCase(changeUserBlocked, (state, action) => {
      state.userBlocked = action.payload;
    })
    .addCase(saveMyUserProfile, (state, action) => {
      state.profile = action.payload;
    })
    .addCase(userLoggout.fulfilled, (state, action) => {
      state = userState;
    })
    .addCase(checkAuthenticatedUser.pending, (state, action) => {
      state.checkingAuthentication = true;
    })
    .addCase(checkAuthenticatedUser.fulfilled, (state, action) => {
      state.checkingAuthentication = false;
    })
    .addCase(checkAuthenticatedUser.rejected, (state, action) => {
      state.checkingAuthentication = false;
    })
    .addCase(authenticateUser.pending, (state, action) => {
      state.authenticatingUser = true;
    })
    .addCase(authenticateUser.fulfilled, (state, action) => {
      state.authenticatingUser = false;
      state.authenticateUserError = "";
    })
    .addCase(authenticateUser.rejected, (state, action: any) => {
      state.authenticatingUser = false;
      state.authenticateUserError = action.payload;
    })
);

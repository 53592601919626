import { createAction } from "@reduxjs/toolkit";
import {
  energySurveyQuestionsInteface,
  teamPowerSurveyQuestionsInterface,
} from "../../interfaces/surveyInterfaces";
import {
  SAVE_TEAM_POWER_SURVEY_QUESTIONS,
  SAVE_WORK_ENERGY_SURVEY_QUESTIONS,
  SAVE_COACHING_FEEDBACK_QUESTIONS,
  SAVE_COACHING_ID,
} from "./constants";

export const saveWorkEnergySurveyQuestions = createAction(
  SAVE_WORK_ENERGY_SURVEY_QUESTIONS,
  (payload: energySurveyQuestionsInteface) => ({
    payload: payload,
  })
);

export const saveTeamPowerSurveyQuestions = createAction(
  SAVE_TEAM_POWER_SURVEY_QUESTIONS,
  (payload: teamPowerSurveyQuestionsInterface) => ({
    payload: payload,
  })
);

export const saveCoachingFeedbackQuestions = createAction(
  SAVE_COACHING_FEEDBACK_QUESTIONS,
  (payload: teamPowerSurveyQuestionsInterface) => ({
    payload: payload,
  })
);

export const saveCoachingId = createAction(
  SAVE_COACHING_ID,
  (payload: number | string) => ({
    payload: payload,
  })
);

export const SAVE_USER_DATA = "SAVE_USER_DATA";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGIN_DATA = "USER_LOGIN_DATA";
export const CHANGE_USER_BLOCKED = "CHANGE_USER_BLOCKED";
export const SAVE_MY_USER_PROFILE = "SAVE_MY_USER_PROFILE";
export const CHECK_AUTHENTICATION = "CHECK_AUTHENTICATION";
export const FETCH_USER_DATA = "FETCH_USER_DATA";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";

export const CREDENTIALS_ERROR = "Email or Password is wrong.";
export const SERVER_ERROR = "Server is not available, please try again later.";
export const CHAT_ACCOUNT_ERROR = "There was a problem with your chat account, please contact us.";
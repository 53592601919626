import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import moment, { Moment } from "moment";
import {
  ENERGY_LEVEL,
  INACTIVE_LICENSE,
  TEAM_POWER,
} from "../../constants/constants";
import { MODALS } from "../../constants/modals";
import {
  selectDayInterface,
  weeksInterface,
} from "../../interfaces/calendarInterfaces";
import { RecommendationSvgInterface } from "../../interfaces/recommendationsInterfaces";
import {
  dimensionsScoreInterface,
  scoreInterface,
} from "../../interfaces/scoresInterfaces";
import {
  optInTeamInterface,
  teamInterface,
  teamMemberWrapper,
} from "../../interfaces/teamInterfaces";
import dashboardService from "../../services/Dashboard.service";
import teamsService from "../../services/Teams.service";
import { getAllUserWeeks, orderDimmensions } from "../../utils/teamPower";
import { openModal } from "../modal/actions";
import {
  SAVE_DAY,
  SAVE_ENERGY_LEVEL_RECOMMENDATIONS,
  SAVE_MONTH_SELECTED,
  SAVE_ORG_DIMENSIONS_SCORES,
  SAVE_ORG_ENERGY_LEVEL,
  SAVE_ORG_SCORES,
  SAVE_SCORES,
  SAVE_TEAMS_DIMENSIONS_SCORES,
  SAVE_TEAM_ENERGY_LEVEL,
  SAVE_TEAM_POWER_ORG_DIMMENSIONS_SCORES,
  SAVE_TEAM_POWER_ORG_SCORES,
  SAVE_TEAM_POWER_RECOMMENDATIONS,
  SAVE_TEAM_POWER_TEAMS_DIMMENSIONS_SCORES,
  SAVE_TEAM_POWER_TEAMS_SCORES,
  SAVE_TEAM_POWER_USER_DIMMENSIONS_SCORES,
  SAVE_TEAM_POWER_USER_SCORES,
  SAVE_TEAM_SCORES,
  SAVE_TRACK_SCORES,
  SAVE_USER_DIMENSIONS_SCORES,
  SAVE_USER_ENERGY_LEVEL,
  SAVE_WEEKS_DATA,
  FETCH_ENERGY_LEVEL_SCORES_DATA,
  CHECK_SURVEY_ANSWERED,
} from "./constants";

export const fetchAllTeamPowerData = createAsyncThunk(
  "FETCH_ALL_TEAM_POWER_DATA",
  async (_, thunkApi) => {
    await Promise.all([
      thunkApi.dispatch(fetchTeamPowerUserData()),
      thunkApi.dispatch(fetchTeamPowerTeamData()),
      thunkApi.dispatch(fetchTeamPowerOrganizationData()),
      thunkApi.dispatch(fetchRecommendations({ type: TEAM_POWER })),
    ]);
  }
);

export const fetchAllEnergyLevelData = createAsyncThunk(
  "FETCH_ALL_ENERGY_LEVEL_DATA",
  async (_, thunkApi) => {
    await Promise.all([
      thunkApi.dispatch(fetchEnergyLevelUserData()),
      thunkApi.dispatch(fetchEnergyLevelTeamData()),
      thunkApi.dispatch(fetchEnergyLevelOrganizationData()),
      thunkApi.dispatch(fetchRecommendations({ type: ENERGY_LEVEL })),
    ]);
  }
);

export const fetchEnergyLevelUserData = createAsyncThunk(
  FETCH_ENERGY_LEVEL_SCORES_DATA,
  async (_, thunkApi) => {
    const state: any = thunkApi.getState();
    const { userTeamMemberIds } = state.userState;
    const { start, end } = state.dashboardState;
    const userScores = await dashboardService.getSurveyScores(
      userTeamMemberIds[0],
      moment(start).format("YYYY-MM-DD"),
      moment(end).format("YYYY-MM-DD"),
      "daily_energy_level_2"
    );

    let newScores: (number | null)[] = [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ];

    userScores.forEach((a: scoreInterface) => {
      const validData = moment(a.date, "DD/MM/YYYY HH:mm A");
      newScores[parseInt(moment(validData).format("d"))] = a.score;
    });

    const energyLevel = await dashboardService.getWorkEnergySumary(
      userTeamMemberIds[0],
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD")
    );

    const dimmensions = await dashboardService.getUserDimensionsScores(
      userTeamMemberIds[0],
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD"),
      "daily_energy_level_2"
    );

    const dimmensionsOrdered = orderDimmensions(dimmensions);
    thunkApi.dispatch(saveUserDimensionsScores(dimmensionsOrdered));

    newScores.shift();
    newScores.pop();

    thunkApi.dispatch(saveTrackScores(newScores));
    thunkApi.dispatch(saveScores(newScores));

    try {
      thunkApi.dispatch(saveEnergyLevel(energyLevel.toFixed(1)));
    } catch (error) {
      thunkApi.dispatch(saveEnergyLevel(energyLevel));
    }
  }
);

export const fetchEnergyLevelTeamData = createAsyncThunk(
  FETCH_ENERGY_LEVEL_SCORES_DATA,
  async (_, thunkApi) => {
    const state: any = thunkApi.getState();
    const { userTeamMemberIds } = state.userState;
    const { team } = state.teamState;
    const { start, end } = state.dashboardState;

    if (team.length > 0) {
      let teamId = "";
      team.forEach((team: teamInterface) => {
        if (
          team.attributes.members.find((a: teamMemberWrapper) =>
            userTeamMemberIds.includes(a.id)
          )
        ) {
          teamId = team.id;
        }
      });

      const dataScores: scoreInterface[] = await dashboardService.getTeamScores(
        teamId,
        moment(start).format("YYYY-MM-DD"),
        moment(end).format("YYYY-MM-DD"),
        "daily_energy_level_2"
      );

      const dataSumary = await dashboardService.getTeamWorkEnergySumary(
        teamId,
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );

      let newScores = [0, 0, 0, 0, 0];

      dataScores.forEach((a: scoreInterface) => {
        const validData = moment(a.date, "DD/MM/YYYY HH:mm A");
        newScores[parseInt(moment(validData).format("d")) - 1] = a.score;
      });

      const dimmensions = await dashboardService.getTeamsDimensionsScores(
        teamId,
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD"),
        "daily_energy_level_2"
      );

      const dimmensionsOrdered = orderDimmensions(dimmensions);

      thunkApi.dispatch(saveTeamScores(newScores));
      thunkApi.dispatch(saveTeamsDimensionsScores(dimmensionsOrdered));
      try {
        thunkApi.dispatch(saveTeamEnergyLevel(dataSumary.toFixed(1)));
      } catch (error) {
        thunkApi.dispatch(saveTeamEnergyLevel(dataSumary));
      }
    }
  }
);

export const fetchEnergyLevelOrganizationData = createAsyncThunk(
  FETCH_ENERGY_LEVEL_SCORES_DATA,
  async (_, thunkApi) => {
    const state: any = thunkApi.getState();
    const { team } = state.teamState;
    const { start, end } = state.dashboardState;

    if (team && team.length > 0) {
      const orgId = team[0].relationships.organisation.data.id;
      const energyLevel =
        await dashboardService.getOrganizationWorkEnergySummary(
          orgId,
          start.format("YYYY-MM-DD"),
          end.format("YYYY-MM-DD")
        );

      const dimmensions =
        await dashboardService.getOrganizationsDimensionsScores(
          orgId,
          start.format("YYYY-MM-DD"),
          end.format("YYYY-MM-DD"),
          "daily_energy_level_2"
        );

      let organizationScores: number[] = [0, 0, 0, 0, 0];

      const organizationScoresData =
        await dashboardService.getOrganizationScores(
          orgId,
          start.format("YYYY-MM-DD"),
          end.format("YYYY-MM-DD"),
          "daily_energy_level_2"
        );

      organizationScoresData.forEach((score: scoreInterface, i: number) => {
        const validData = moment.utc(score.date, "DD/MM/YYYY HH:mm A").local();
        organizationScores[parseInt(moment(validData).format("d")) - 1] =
          score.score;
      });

      const dimmensionsOrdered = orderDimmensions(dimmensions);
      thunkApi.dispatch(saveOrgDimensionsScores(dimmensionsOrdered));

      thunkApi.dispatch(saveOrgScores(organizationScores));

      try {
        thunkApi.dispatch(saveOrgEnergyLevel(energyLevel.toFixed(1)));
      } catch (error) {
        thunkApi.dispatch(saveOrgEnergyLevel(energyLevel));
      }
    }
  }
);

export const fetchTeamPowerUserData = createAsyncThunk(
  "FETCH_TEAM_POWER_USER_DATA",
  async (_, thunkApi) => {
    const state: any = thunkApi.getState();
    const { userTeamMemberIds, user } = state.userState;
    const { monthSelected } = state.dashboardState;

    const monthWeeks = getAllUserWeeks(monthSelected, user.registered);
    let scores: (number | null)[] = [];

    for (const week of monthWeeks) {
      const score: scoreInterface[] = await dashboardService.getSurveyScores(
        userTeamMemberIds[0],
        moment(week.start).format("YYYY-MM-DD"),
        moment(week.end).format("YYYY-MM-DD"),
        "team_power"
      );
      if (score.length) {
        scores.push(score[0].score);
      } else {
        scores.push(null);
      }
    }

    const dimmensions = await dashboardService.getUserDimensionsScores(
      userTeamMemberIds[0],
      monthWeeks[0].start.format("YYYY-MM-DD"),
      monthWeeks[monthWeeks.length - 1].end.format("YYYY-MM-DD"),
      "team_power"
    );

    const dimmensionsOrdered = orderDimmensions(dimmensions);

    thunkApi.dispatch(saveTeamPowerUserScores(scores));
    thunkApi.dispatch(saveTeamPowerUserDimmensionsScores(dimmensionsOrdered));
    thunkApi.dispatch(saveTeamPowerDays(monthWeeks));
  }
);

export const fetchTeamPowerTeamData = createAsyncThunk(
  "FETCH_TEAM_POWER_TEAM_DATA",
  async (_, thunkApi) => {
    const state: any = thunkApi.getState();
    const { user } = state.userState;
    const { monthSelected } = state.dashboardState;
    const { myDataTeam } = state.teamState;

    const monthWeeks = getAllUserWeeks(monthSelected, user.registered);
    let scores: (number | null)[] = [];

    for (const week of monthWeeks) {
      const score: scoreInterface[] = await dashboardService.getTeamScores(
        myDataTeam.team.id,
        moment(week.start).format("YYYY-MM-DD"),
        moment(week.end).format("YYYY-MM-DD"),
        "team_power"
      );
      if (score.length) {
        scores.push(score[0].score);
      } else {
        scores.push(null);
      }
    }

    const dimmensions = await dashboardService.getTeamsDimensionsScores(
      myDataTeam.team.id,
      monthWeeks[0].start.format("YYYY-MM-DD"),
      monthWeeks[monthWeeks.length - 1].end.format("YYYY-MM-DD"),
      "team_power"
    );

    const dimmensionsOrdered = orderDimmensions(dimmensions);

    thunkApi.dispatch(saveTeamPowerTeamScores(scores));
    thunkApi.dispatch(saveTeamPowerTeamsDimmensionsScores(dimmensionsOrdered));
  }
);

export const fetchTeamPowerOrganizationData = createAsyncThunk(
  "FETCH_TEAM_POWER_ORGANIZATION_DATA",
  async (_, thunkApi) => {
    const state: any = thunkApi.getState();
    const { user } = state.userState;
    const { monthSelected } = state.dashboardState;
    const { team } = state.teamState;

    const monthWeeks = getAllUserWeeks(monthSelected, user.registered);
    let scores: (number | null)[] = [];

    for (const week of monthWeeks) {
      const score: scoreInterface[] =
        await dashboardService.getOrganizationScores(
          team[0].relationships.organisation.data.id,
          moment(week.start).format("YYYY-MM-DD"),
          moment(week.end).format("YYYY-MM-DD"),
          "team_power"
        );
      if (score.length) {
        scores.push(score[0].score);
      } else {
        scores.push(null);
      }
    }

    const dimmensions = await dashboardService.getOrganizationsDimensionsScores(
      team[0].relationships.organisation.data.id,
      monthWeeks[0].start.format("YYYY-MM-DD"),
      monthWeeks[monthWeeks.length - 1].end.format("YYYY-MM-DD"),
      "team_power"
    );

    const dimmensionsOrdered = orderDimmensions(dimmensions);

    thunkApi.dispatch(saveTeamPowerOrgScores(scores));
    thunkApi.dispatch(saveTeamPowerOrgDimmensionsScores(dimmensionsOrdered));
  }
);

export const checkSurveyAnswered = createAsyncThunk(
  CHECK_SURVEY_ANSWERED,
  async (_, thunkApi) => {
    const state: any = thunkApi.getState();
    const { orgLicenseType, userTeamMemberIds } = state.userState;
    const { myDataTeam } = state.teamState;

    if (
      !(moment().weekday() === 0) &&
      !(moment().weekday() === 6) &&
      orgLicenseType !== INACTIVE_LICENSE
    ) {
      const scoresData = await dashboardService.getSurveyScores(
        userTeamMemberIds[0],
        moment.utc().format("YYYY-MM-DD"),
        moment.utc().format("YYYY-MM-DD"),
        "daily_energy_level_2"
      );
      // if (scoresData.length === 0 && myDataTeam.can_receive_automated_msg) {
      //   thunkApi.dispatch(openModal(MODALS.TRACK_SELF_REFLECTION));
      // }
    }
  }
);

export const saveWeeksDataAndFetch = createAsyncThunk(
  "SAVE_WEEK_SELECTED",
  async ({ start, end }: any, thunkApi) => {
    thunkApi.dispatch(
      SaveWeeksData({
        start,
        end,
      })
    );
    thunkApi.dispatch(fetchAllEnergyLevelData());
  }
);

export const saveMonthDataAndFetch = createAsyncThunk(
  "SAVE_MONTH_SELECTED",
  async ({ month }: any, thunkApi) => {
    thunkApi.dispatch(saveMonthSelected(month));
    thunkApi.dispatch(fetchAllTeamPowerData());
    thunkApi.dispatch(fetchRecommendations({ type: TEAM_POWER }));
  }
);

export const fetchRecommendations = createAsyncThunk(
  "FETCH_RECOMMENDATIONS",
  async ({ type }: any, thunkApi) => {
    const state: any = thunkApi.getState();
    const { userTeamMemberIds } = state.userState;
    const { start, end } = state.dashboardState;

    if (start && end) {
      const recommendations = await dashboardService.getRecommendationsWeek(
        userTeamMemberIds[0],
        moment(start).format("YYYY-MM-DD"),
        moment(end).format("YYYY-MM-DD"),
        type
      );

      if (type === ENERGY_LEVEL) {
        thunkApi.dispatch(SaveEnergyLevelRecommendations(recommendations));
      } else {
        thunkApi.dispatch(SaveTeamPowerRecommendations(recommendations));
      }
    }
  }
);

export const fetchOptInMembers = createAsyncThunk(
  "FETCH_OPT_IN_MEMBERS",
  async (_, thunkApi) => {
    const state: any = thunkApi.getState();
    const { myDataTeam } = state.teamState;
    const data: optInTeamInterface = await teamsService.getTeamMembersOptIn(
      myDataTeam.team.id
    );
    return data;
  }
);

export const SaveWeeksData = createAction(
  SAVE_WEEKS_DATA,
  (payload: weeksInterface) => ({
    payload: payload,
  })
);

export const SaveDayData = createAction(
  SAVE_DAY,
  (payload: selectDayInterface | null) => ({
    payload: payload,
  })
);

export const SaveEnergyLevelRecommendations = createAction(
  SAVE_ENERGY_LEVEL_RECOMMENDATIONS,
  (payload: RecommendationSvgInterface) => ({
    payload: payload,
  })
);

export const SaveTeamPowerRecommendations = createAction(
  SAVE_TEAM_POWER_RECOMMENDATIONS,
  (payload: RecommendationSvgInterface) => ({
    payload: payload,
  })
);

export const saveTrackScores = createAction(
  SAVE_TRACK_SCORES,
  (payload: (number | null)[]) => ({
    payload: payload,
  })
);

export const saveScores = createAction(SAVE_SCORES, (payload: any) => ({
  payload: payload,
}));

export const saveTeamScores = createAction(
  SAVE_TEAM_SCORES,
  (payload: number[]) => ({
    payload: payload,
  })
);

export const saveEnergyLevel = createAction(
  SAVE_USER_ENERGY_LEVEL,
  (payload: number | string) => ({
    payload: payload,
  })
);

export const saveTeamEnergyLevel = createAction(
  SAVE_TEAM_ENERGY_LEVEL,
  (payload: number | string) => ({
    payload: payload,
  })
);

export const saveOrgScores = createAction(
  SAVE_ORG_SCORES,
  (payload: number[]) => ({
    payload: payload,
  })
);

export const saveOrgEnergyLevel = createAction(
  SAVE_ORG_ENERGY_LEVEL,
  (payload: number | string) => ({
    payload: payload,
  })
);

export const saveOrgDimensionsScores = createAction(
  SAVE_ORG_DIMENSIONS_SCORES,
  (payload: dimensionsScoreInterface) => ({
    payload: payload,
  })
);

export const saveTeamsDimensionsScores = createAction(
  SAVE_TEAMS_DIMENSIONS_SCORES,
  (payload: dimensionsScoreInterface) => ({
    payload: payload,
  })
);

export const saveUserDimensionsScores = createAction(
  SAVE_USER_DIMENSIONS_SCORES,
  (payload: dimensionsScoreInterface) => ({
    payload: payload,
  })
);

export const saveMonthSelected = createAction(
  SAVE_MONTH_SELECTED,
  (payload: Moment) => ({
    payload: payload,
  })
);

export const saveTeamPowerUserScores = createAction(
  SAVE_TEAM_POWER_USER_SCORES,
  (payload: (number | null)[]) => ({
    payload: payload,
  })
);

export const saveTeamPowerTeamScores = createAction(
  SAVE_TEAM_POWER_TEAMS_SCORES,
  (payload: (number | null)[]) => ({
    payload: payload,
  })
);

export const saveTeamPowerOrgScores = createAction(
  SAVE_TEAM_POWER_ORG_SCORES,
  (payload: (number | null)[]) => ({
    payload: payload,
  })
);

export const saveTeamPowerUserDimmensionsScores = createAction(
  SAVE_TEAM_POWER_USER_DIMMENSIONS_SCORES,
  (payload: dimensionsScoreInterface) => ({
    payload: payload,
  })
);

export const saveTeamPowerTeamsDimmensionsScores = createAction(
  SAVE_TEAM_POWER_TEAMS_DIMMENSIONS_SCORES,
  (payload: dimensionsScoreInterface) => ({
    payload: payload,
  })
);

export const saveTeamPowerOrgDimmensionsScores = createAction(
  SAVE_TEAM_POWER_ORG_DIMMENSIONS_SCORES,
  (payload: dimensionsScoreInterface) => ({
    payload: payload,
  })
);

export const saveTeamPowerDays = createAction(
  "SET_TEAM_POWER_DAYS",
  (payload: weeksInterface[]) => ({
    payload: payload,
  })
);

import { createReducer } from "@reduxjs/toolkit";
import { userLoggout } from "../user/actions";
import {
  saveUserChatProfile,
  saveUserChatNotes,
  setBookSessionURL,
  setLoadingURL,
} from "./actions";

interface ChatState {
  profile: any;
  notes: any;
  bookSessionURL: string;
  loadingURL: boolean;
}

const chatState = {
  profile: null,
  notes: "",
  bookSessionURL: "",
  loadingURL: false,
} as ChatState;

export const chatReducer = createReducer(chatState, (builder) =>
  builder
    .addCase(saveUserChatProfile, (state, action) => {
      state.profile = action.payload;
    })
    .addCase(saveUserChatNotes, (state, action) => {
      state.notes = action.payload;
    })
    .addCase(setBookSessionURL, (state, action) => {
      state.bookSessionURL = action.payload;
    })
    .addCase(setLoadingURL, (state, action) => {
      state.loadingURL = action.payload;
    })
    .addCase(userLoggout.fulfilled, (state, action) => {
      state = chatState;
    })
);

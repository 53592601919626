export class StorageService {
  set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  get(key: string): string {
    return localStorage.getItem(key) as string;
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}

const storageService = new StorageService();
export default storageService;

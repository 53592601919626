import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import reportWebVitals from "./reportWebVitals";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-MM47MLP',
  dataLayerName: 'PageDataLayer'
}

TagManager.initialize(tagManagerArgs)

const render = () => {
  require('./AppRenderer');
};
render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

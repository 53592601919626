import axios from "axios";
import storageService from "./Storage.service";
import { AuthService } from "./Auth.service";
import { RecommendationSvgInterface } from "../interfaces/recommendationsInterfaces";

const API_URL = process.env.REACT_APP_URL_API;

export class DashboardService {
    constructor(private _authService: AuthService) {
    }

    getRecommendationsWeek(memberId: number, start: string, end: string, surveyType: string) {
        const currentToken = storageService.get("access_token");
        const headers = {
            'Authorization': `jwt ${currentToken}`
        }
        return new Promise<any>((resolve, reject) => {
            axios.get(`${API_URL}/api/chat/team-members/${memberId}/surveys/${surveyType}/recommendations-sent/?start_date=${start}&end_date=${end}`, {
                headers: headers
            }).then((res) => {
                if (res.status === 200) {
                    resolve(res.data.data)
                } else {
                    throw new Error('User not found')
                }
            })
                .catch((err) => {
                    resolve(null)
                    reject(err);
                });
        })
    };

    getSurveyScores(memberId: number, start: string, end: string, surveyType: string) {
        const currentToken = storageService.get("access_token");
        const headers = {
            'Authorization': `jwt ${currentToken}`
        }

        return new Promise<[]>((resolve, reject) => {
            axios.get(`${API_URL}/api/chat/team-members/${memberId}/surveys/${surveyType}/scores/?start_date=${start}&end_date=${end}`, {
                headers
            }).then((res) => {
                resolve(res.data.data);
            })
                .catch((err) => {
                    reject(err);
                });
        })
    };

    getWorkEnergySumary(memberId: number, start: string, end: string) {
        const currentToken = storageService.get("access_token");
        const headers = {
            'Authorization': `jwt ${currentToken}`
        }

        return new Promise<any>((resolve, reject) => {
            axios.get(`${API_URL}/api/chat/team-members/${memberId}/work-energy-summary/?start_date=${start}&end_date=${end}`, {
                headers
            }).then((res) => {
                resolve(res.data.data.result);
            })
                .catch((err) => {
                    reject(err);
                });
        })
    };

    getTeamScores(teamId: string, start: string, end: string, surveyType: string) {
        const currentToken = storageService.get("access_token");
        const headers = {
            'Authorization': `jwt ${currentToken}`
        }

        return new Promise<any>((resolve, reject) => {
            axios.get(`${API_URL}/api/public/teams/${teamId}/surveys/${surveyType}/survey-scores/?start_date=${start}&end_date=${end}`, {
                headers
            }).then((res) => {
                resolve(res.data.data.scores);
            })
                .catch((err) => {
                    reject(err);
                });
        })
    };

    getTeamWorkEnergySumary(teamId: string, start: string, end: string) {
        const currentToken = storageService.get("access_token");
        const headers = {
            'Authorization': `jwt ${currentToken}`
        }

        return new Promise<any>((resolve, reject) => {
            axios.get(`${API_URL}/api/public/teams/${teamId}/work-energy-summary/?start_date=${start}&end_date=${end}/`, {
                headers
            }).then((res) => {
                resolve(res.data.data.result);
            })
                .catch((err) => {
                    reject(err);
                });
        })
    };

    getDimensionsScore(memberId: number, date: string, surveyType: string) {
        const currentToken = storageService.get("access_token");
        const headers = {
            'Authorization': `jwt ${currentToken}`
        }

        return new Promise<any>((resolve, reject) => {
            axios.get(`${API_URL}/api/chat/team-members/${memberId}/surveys/${surveyType}/dimmensions-score/?start_date=${date}&end_date=${date}/`, {
                headers
            }).then((res) => {
                resolve(res.data.data);
            })
                .catch((err) => {
                    reject(err);
                });
        })
    };

    getOrganizationScores(orgId: string, start: string, end: string, surveyType: string) {
        const currentToken = storageService.get("access_token");
        const headers = {
            'Authorization': `jwt ${currentToken}`
        }

        return new Promise<any>((resolve, reject) => {
            axios.get(`${API_URL}/api/public/organisations/${orgId}/surveys/${surveyType}/survey-scores/?start_date=${start}&end_date=${end}/`, {
                headers
            }).then((res) => {
                resolve(res.data.data.scores);
            })
                .catch((err) => {
                    reject(err);
                });
        })
    };

    getOrganizationWorkEnergySummary(orgId: string, start: string, end: string) {
        const currentToken = storageService.get("access_token");
        const headers = {
            'Authorization': `jwt ${currentToken}`
        }

        return new Promise<any>((resolve, reject) => {
            axios.get(`${API_URL}/api/public/organisations/${orgId}/work-energy-summary/?start_date=${start}&end_date=${end}/`, {
                headers
            }).then((res) => {
                resolve(res.data.data.result);
            })
                .catch((err) => {
                    reject(err);
                });
        })
    };

    getOrganizationsDimensionsScores(orgId: string, start: string, end: string, surveyType: string) {
        const currentToken = storageService.get("access_token");
        const headers = {
            'Authorization': `jwt ${currentToken}`
        }

        return new Promise<any>((resolve, reject) => {
            axios.get(`${API_URL}/api/public/organisations/${orgId}/surveys/${surveyType}/dimmensions-score/?start_date=${start}&end_date=${end}/`, {
                headers
            })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        })
    };

    getTeamsDimensionsScores(teamId: string, start: string, end: string, surveyType: string) {
        const currentToken = storageService.get("access_token");
        const headers = {
            'Authorization': `jwt ${currentToken}`
        }

        return new Promise<any>((resolve, reject) => {
            axios.get(`${API_URL}/api/public/teams/${teamId}/surveys/${surveyType}/dimmensions-score/?start_date=${start}&end_date=${end}/`, {
                headers
            })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        })
    };

    getUserDimensionsScores(memberId: number, start: string, end: string, surveyType: string) {
        const currentToken = storageService.get("access_token");
        const headers = {
            'Authorization': `jwt ${currentToken}`
        }

        return new Promise<any>((resolve, reject) => {
            axios.get(`${API_URL}/api/chat/team-members/${memberId}/surveys/${surveyType}/dimmensions-score/?start_date=${start}&end_date=${end}/`, {
                headers
            })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        })
    };
}
const authService = new AuthService();
const dashboardService = new DashboardService(authService);
export default dashboardService;

import Cookies from "universal-cookie";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { CometChat } from "@cometchat-pro/chat";
import {
  SAVE_USER_DATA,
  USER_LOGIN_DATA,
  CHANGE_USER_BLOCKED,
  SAVE_MY_USER_PROFILE,
  USER_LOGOUT,
  CHECK_AUTHENTICATION,
  FETCH_USER_DATA,
  AUTHENTICATE_USER,
  CREDENTIALS_ERROR,
  SERVER_ERROR,
  CHAT_ACCOUNT_ERROR,
} from "./constants";
import { authInterface, UserInterfaces } from "../../interfaces/userInterfaces";
import authService from "../../services/Auth.service";
import { fetchTeamData } from "../team/actions";
import chatService from "../../services/Chat.service";
import userService from "../../services/User.service";
import { handleCometChatLogin } from "../../utils/cometChat";
import { LoginRequest } from "../../domain/request/login-request";

export const authenticateUser = createAsyncThunk(
  AUTHENTICATE_USER,
  async (loginRequest: LoginRequest, thunkApi) => {
    return await authService
      .login(loginRequest)
      .then(async (userData) => {
        if (userData) {
          try {
            await handleCometChatLogin(userData);
          } catch (error) {
            thunkApi.dispatch(userLoggout());
            return thunkApi.rejectWithValue(CHAT_ACCOUNT_ERROR);
          }
          await thunkApi.dispatch(fetchTeamData(userData.team_member_ids));
          thunkApi.dispatch(fetchUserData(userData.user_id));
          thunkApi.dispatch(saveUserLoginData(userData));
          if (userData.user_uuid) {
            await chatService
              .getUserChatProfile(userData.user_uuid)
              .then((user) => {
                user.data.uid = userData.user_uuid;
                thunkApi.dispatch(saveMyUserProfile(user.data));
              })
              .catch((err) => {
                thunkApi.dispatch(saveMyUserProfile(null));
              });
          }
        } else {
          return thunkApi.rejectWithValue(CREDENTIALS_ERROR);
        }
      })
      .catch(() => {
        return thunkApi.rejectWithValue(SERVER_ERROR);
      });
  }
);

export const checkAuthenticatedUser = createAsyncThunk(
  CHECK_AUTHENTICATION,
  async (userId, thunkApi) => {
    if (authService.isAuthenticated()) {
      let refreshedData: authInterface = await authService.refreshToken();
      if (refreshedData) {
        try {
          await handleCometChatLogin(refreshedData);
        } catch (error) {
          thunkApi.dispatch(userLoggout());
        }
        await thunkApi.dispatch(fetchTeamData(refreshedData.team_member_ids));
        thunkApi.dispatch(fetchUserData(refreshedData.user_id));
        thunkApi.dispatch(saveUserLoginData(refreshedData));
        if (refreshedData.user_uuid) {
          await chatService
            .getUserChatProfile(refreshedData.user_uuid)
            .then((user) => {
              user.data.uid = refreshedData.user_uuid;
              thunkApi.dispatch(saveMyUserProfile(user.data));
            })
            .catch((err) => {
              thunkApi.dispatch(saveMyUserProfile(null));
            });
        }
      } else {
        thunkApi.dispatch(userLoggout());
      }
    } else {
      thunkApi.dispatch(userLoggout());
    }
  }
);

export const fetchUserData = createAsyncThunk(
  FETCH_USER_DATA,
  async (userId: number, thunkApi) => {
    const userData = await userService.getUser(userId);
    if (userData) {
      thunkApi.dispatch(saveUserData(userData));
    }
  }
);

export const saveUserData = createAction(
  SAVE_USER_DATA,
  (payload: UserInterfaces | null) => ({
    payload: payload,
  })
);

export const saveUserLoginData = createAction(
  USER_LOGIN_DATA,
  (payload: authInterface) => ({
    payload: payload,
  })
);

export const changeUserBlocked = createAction(
  CHANGE_USER_BLOCKED,
  (payload: boolean) => ({
    payload: payload,
  })
);

export const saveMyUserProfile = createAction(
  SAVE_MY_USER_PROFILE,
  (payload: any) => ({
    payload: payload,
  })
);

export const userLoggout = createAsyncThunk(USER_LOGOUT, () => {
  const cookies = new Cookies();
  cookies.remove("tracking", { path: "/" });
  authService.logout();
  CometChat.logout();
});

export const ROUTES = {
  sign_up: "/sign_up",
  main: "/app",
  chat: "/app/chat",
  dashboard: "/app/dashboard",
  login: "/login",
  forgot_password: "/forgot_password",
  reset_password: "/:reset-password",
  poster: "/app/posters",
  activate_account: "/:activate-account",
  slack_redirect: "/slack_redirect/:code?",
  email_preferences: "/email-preferences/:userId",
};

export const ADMIN_ROOT: string = "/app";
export const INACTIVE_LICENSE: string = "Inactive";
export const IS_AUTH_GUARD_ACTIVE = true;
export const SLACK_REDIRECT_URL = "slackRedirectUrl";

export const ENERGY_LEVEL = "daily_energy_level_2";
export const TEAM_POWER = "team_power";

export const ROLE_TEAM_MEMBER = 2
export const ROLE_TEAM_LEADER = 3
export const ROLE_BUYER = 5
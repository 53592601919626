import { createAction } from "@reduxjs/toolkit";
import { organizationInterface } from "../../interfaces/organizationInterfaces";
import { SAVE_ORGANIZATION_DATA } from "./constants";

export const saveOrganizationData = createAction(
  SAVE_ORGANIZATION_DATA,
  (payload: organizationInterface) => ({
    payload: payload,
  })
);

import { SAVE_SLACK_REDIRECT_URL } from "./constants";

import { createAction } from "@reduxjs/toolkit";

export const setSlackRedirectUrl = createAction(
  SAVE_SLACK_REDIRECT_URL,
  (payload: string) => ({
    payload: payload,
  })
);

import { createReducer } from "@reduxjs/toolkit";
import { userLoggout } from "../user/actions";
import {
  saveWorkEnergySurveyQuestions,
  saveTeamPowerSurveyQuestions,
  saveCoachingFeedbackQuestions,
  saveCoachingId,
} from "./actions";

interface SurveyState {
  workEnergySurvey: any;
  teamPowerSurvey: any;
  coachingFeedbackSurvey: any;
  coachingId: any;
}

const surveyState = {
  workEnergySurvey: null,
  teamPowerSurvey: null,
  coachingFeedbackSurvey: null,
  coachingId: null,
} as SurveyState;

export const surveyReducer = createReducer(surveyState, (builder) =>
  builder
    .addCase(saveWorkEnergySurveyQuestions, (state, action) => {
      state.workEnergySurvey = action.payload;
    })
    .addCase(saveTeamPowerSurveyQuestions, (state, action) => {
      state.teamPowerSurvey = action.payload;
    })
    .addCase(saveCoachingFeedbackQuestions, (state, action) => {
      state.coachingFeedbackSurvey = action.payload;
    })
    .addCase(saveCoachingId, (state, action) => {
      state.coachingId = action.payload;
    })
    .addCase(userLoggout.fulfilled, (state, action) => {
      state = surveyState;
    })
);

import { CometChat } from "@cometchat-pro/chat";

export const handleCometChatLogin = (userData: any): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    if (userData.access_chat) {
      if (userData.cometchat_token) {
        await CometChat.login(userData.cometchat_token).then(
          (User) => {
            console.log("Login Successful:", { User });
            resolve(true);
          },
          (error) => {
            console.log("Login failed with exception:", { error });
            reject(false);
          }
        );
      }
    }

    resolve(true);
  });
};

import axios from "axios";
import storageService from "./Storage.service";
import {AuthService} from "./Auth.service";
import { updateUserDataInterface, updateUserInterface, updateEmailPreferencesInterface } from "../interfaces/userInterfaces";

const API_URL = process.env.REACT_APP_URL_API;

export class UserService {
    constructor(private _authService: AuthService) {
    }
    async getUser(userId: number){
        const currentToken = storageService.get("access_token");
        const headers = {
            'Authorization': `jwt ${currentToken}`
        }
        return new Promise<any>((resolve, reject) => {
            axios.get(`${API_URL}/api/public/users/${userId}/`, {
                headers: headers
            }).then((res) => {
                if(res.status === 200){
                    resolve(res.data.data.attributes)
                } else {
                    throw new Error('User not found')
                }
            })
            .catch((err) => {
                this._authService.refreshToken();
                reject(err);
            });
        })
    }

    async getEmailPreferences(userId: string){
        return new Promise<any>((resolve, reject) => {
            axios.get(`${API_URL}/api/public/users/${userId}/email-permissions/`).then((res) => {
                if(res.status === 200){
                    resolve(res.data.data)
                } else {
                    throw new Error('User not found')
                }
            })
            .catch((err) => {
                reject(err);
            });
        })
    }

    async updateEmailPreferences(data: updateEmailPreferencesInterface, userId: string) {
        return new Promise<any>((resolve, reject) => {
            axios.put(`${API_URL}/api/public/users/${userId}/email-permissions/`, data).then((res) => {
                if(res.status === 200){
                    resolve(res.data.data)
                } else {
                    throw new Error('User not found')
                }
            })
                .catch((err) => {
                    reject(err);
                });
        })
    }

    async updateUser(data: updateUserInterface | updateUserDataInterface, userId: number) {
        const currentToken = storageService.get("access_token");
        const headers = {
            'Authorization': `jwt ${currentToken}`,
            "Content-Type": "application/json",
        }
        return new Promise<any>((resolve, reject) => {
            axios.put(`${API_URL}/api/public/users/${userId}/`, data,{
                headers: headers
            }).then((res) => {
                if(res.status === 200){
                    resolve(res.data.data)
                } else {
                    throw new Error('User not found')
                }
            })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    async updateTeamMember(data: updateUserInterface, memberId: number) {
        const currentToken = storageService.get("access_token");
        const headers = {
            'Authorization': `jwt ${currentToken}`,
            'Content-Type': 'application/json'
        }
        return new Promise<any>((resolve, reject) => {
            axios.put(`${API_URL}/api/public/team-members/${memberId}/`, data,{
                headers: headers
            }).then((res) => {
                if(res.status === 200){
                    resolve(res.data.data)
                } else {
                    throw new Error('User not found')
                }
            })
                .catch((err) => {
                    this._authService.refreshToken();
                    reject(err);
                });
        })
    }

}

const authService = new AuthService();
const userService = new UserService(authService);
export default userService;
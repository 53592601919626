import { createAction } from "@reduxjs/toolkit";
import { SET_UNREAD_MESSAGES, REMOVE_UNREAD_MESSAGES } from "./constants";

export const setUnreadMessages = createAction(
  SET_UNREAD_MESSAGES,
  (payload: string[]) => ({
    payload: payload,
  })
);

export const removeUnreadMessages = createAction(
  REMOVE_UNREAD_MESSAGES,
  (payload: string) => ({
    payload: payload,
  })
);

import { createReducer } from "@reduxjs/toolkit";
import { userLoggout } from "../user/actions";
import { removeUnreadMessages, setUnreadMessages } from "./actions";

interface MenuState {
  unreadMessages: string[];
}

const menuState = {
  unreadMessages: [],
} as MenuState;

export const menuReducer = createReducer(menuState, (builder) =>
  builder
    .addCase(setUnreadMessages, (state, action) => {
      state.unreadMessages = [...state.unreadMessages, ...action.payload];
    })
    .addCase(removeUnreadMessages, (state, action) => {
      state.unreadMessages = state.unreadMessages.filter(
        (id) => id !== action.payload
      );
    })
    .addCase(userLoggout.fulfilled, (state, action) => {
      state = menuState;
    })
);

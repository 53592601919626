import axios from "axios";
import storageService from "./Storage.service";

const API_URL = process.env.REACT_APP_URL_API;

export class TeamsService {

  getTeams() {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`
    }

    return new Promise<any>((resolve, reject) => {
      axios.get(API_URL + "/api/public/teams/", {
        headers: headers
      }).then((res) => {
        resolve(res.data.data);
      })
        .catch((err) => {
          reject(err);
        });
    })
  };

  getUserTeams () {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`
    }

    return new Promise<any>((resolve, reject) => {
      axios.get(API_URL + "/api/public/teams?is_member=true/", {
        headers: headers
      }).then((res) => {
        resolve(res.data.data);
      })
        .catch((err) => {
          reject(err);
        });
    })
  }

  getUserWorkspaces () {
	const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`
    }

    return new Promise<any>((resolve, reject) => {
      axios.get(API_URL + "/api/chat/slack-workspaces/", {
        headers: headers
      }).then((res) => {
        resolve(res.data.data);
      })
        .catch((err) => {
          reject(err);
        });
    })
  }

  getMembersToInvite() {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`
    }

    return new Promise<any>((resolve, reject) => {
      axios.get(API_URL + "/api/chat/workspace-members/?exclude_teammembers=true", {
        headers: headers
      }).then((res) => {
        resolve(res.data.data);
      })
        .catch((err) => {
          reject(err);
        });
    })
  }

  getTeamMembersOptIn(teamId: string) {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`
    }

    return new Promise<any>((resolve, reject) => {
      axios.get(API_URL + `/api/public/teams/${teamId}/opt-in/team-members/`, {
        headers: headers
      }).then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
    })
  };

  addSlackMember(teamId: number, memberId: number, memberRole: string) {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`
    }

    let data = new FormData();
    data.append('team_id', `${teamId}`);
    data.append('role_name', `${memberRole}`);
    data.append('member_id', `${memberId}`);

    return new Promise<any>((resolve, reject) => {
      axios.post(API_URL + '/api/public/team-members/', data , {
        headers: headers
      }).then((res) => {
        if(res.status === 201){
          resolve(res.data)
        } else {
          throw new Error('')
        }
      })
        .catch((err) => {
          reject(err);
        });
    })
  };

  addEmailMember(teamId: number, email: string, memberRole: string) {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`,
      'Content-Type': 'application/json'
    }

    let data = {
      team_id: teamId,
      role_name: memberRole,
      email: email,
    }

    return new Promise<any>((resolve, reject) => {
      axios.post(API_URL + '/api/public/team-members/', data , {
        headers: headers
      }).then((res) => {
        if(res.status === 201){
          resolve(res.data)
        } else {
          throw new Error('')
        }
      })
        .catch((err) => {
          reject(err);
        });
    })
  };

  resendEmailInvitation(memberId: number) {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`,
    }

    let data = new FormData();
    data.append('team_member_id', `${memberId}`);
    data.append('type_email', `user_invitation`);

    return new Promise<any>((resolve, reject) => {
      axios.post(API_URL + '/api/chat/send-email/', data , {
        headers: headers
      })
        .then((res) => {
          if(res.status === 200){
            resolve(true)
          } else {
            throw new Error('')
          }
        })
        .catch((err) => {
          reject(false);
        });
    })
  };

  removeEmailInvitation(memberId: number) {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`,
    }

    return new Promise<any>((resolve, reject) => {
      axios.delete(API_URL + `/api/public/team-members/${memberId}/remove-invitation/`, {
        headers: headers
      })
        .then((res) => {
          if(res.status === 200){
            resolve(true)
          } else {
            throw new Error('')
          }
        })
        .catch((err) => {
          reject(false);
		});
    })
  };

  getRoles() {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`
    }

    return new Promise<any>((resolve, reject) => {
      axios.get(API_URL + "/api/public/roles/", {
        headers: headers
      }).then((res) => {
        resolve(res.data.data);
      })
        .catch((err) => {
          reject(err);
        });
    })
  };

  createTeam(teamName: string, orgId: string) {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`,
      'Content-Type': 'application/json'
    }

    let data = {
      team_name: teamName,
      organisation_id: parseInt(orgId),
    }

    return new Promise<any>((resolve, reject) => {
      axios.post(API_URL + '/api/public/teams/', data , {
        headers: headers
      }).then((res) => {
        if(res.status === 201){
          resolve(true)
        } else {
          throw new Error('')
        }
      })
        .catch((err) => {
          reject(err);
        });
    })
  };

}

const teamsService = new TeamsService();
export default teamsService;

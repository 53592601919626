import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { LoginRequest } from "../domain/request/login-request";
import { ForgotPasswordRequest } from '../domain/request/forgot-password-request';
import { LoginResponse } from "../domain/response/login-response";
import storageService from "./Storage.service";
import { UpdatePasswordRequest } from "../domain/request/update-password-request";
import { ResetToken } from '../domain/request/validate-reset-token';
import { SignUpRequest } from "../domain/request/sign-up-request";
import { ConfirmToken } from "../domain/request/validate-confirm-token";
import { authInterface } from "../interfaces/userInterfaces";
import { CometChat } from "@cometchat-pro/chat";

const API_BASE_URL = process.env.REACT_APP_URL_API;
const LS_KEY_ACCESS_TOKEN = "access_token";

export class AuthService {
  login(loginRequest: LoginRequest): Promise<any> {
    const url: string = `${API_BASE_URL}/api/token/`;
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/vnd.api+json",
      },
    };

    const data = {
      data: {
        type: "obtainJSONWebTokens",
        id: "",
        attributes: {
          username: loginRequest.username,
          password: loginRequest.password,
        },
      },
    };

    return new Promise<authInterface | null>((resolve, reject) => {
      axios
        .post(url, data, config)
        .then((res: AxiosResponse<any>) => {
          if (200 === res.status && res.data.data.token) {
            storageService.set(LS_KEY_ACCESS_TOKEN, res.data.data.token);

            resolve(res.data.data);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            500 !== error.response.status &&
            404 !== error.response.status
          ) {
            resolve(null);
          } else {
            reject(error);
          }
        });
    });
  }

  forgotPassword(forgotPasswordRequest: ForgotPasswordRequest): Promise<any> {
    const url: string = `${API_BASE_URL}/api/chat/send-email/`;
    const config: AxiosRequestConfig = {
      headers: { 
        'Authorization': '', 
      },
    };

    let data = new FormData();
    data.append('email', forgotPasswordRequest.email);
    data.append('type_email', 'reset_password');

    return new Promise<boolean>((resolve, reject) => {
      axios
        .post(url, data, config)
        .then((res: AxiosResponse<any>) => {
          if (200 === res.status) {
            resolve(true);
          } else {
            throw new Error('');
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error);
          } else {
            reject(false);
          }
        });
    });
  }

  validateResetToken(resetToken: ResetToken): Promise<any> {
    const url: string = `${API_BASE_URL}/api/chat/reset-password/?reset_token=${resetToken.resetToken}`;

    return new Promise<boolean>((resolve, reject) => {
      axios
        .get(url)
        .then((res: AxiosResponse<any>) => {
          if (200 === res.status && res.data.data.is_valid === true) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            500 !== error.response.status &&
            404 !== error.response.status
          ) {
            console.log(error)
            resolve(false);
          } else {
            console.log(error)
            reject(error);
          }
        });
    });
  }

  resetPassword(UpdatePasswordRequest: UpdatePasswordRequest): Promise<any> {
    const url: string = `${API_BASE_URL}/api/chat/reset-password/`;

    let data = new FormData();
    data.append('reset_token', UpdatePasswordRequest.resetToken);
    data.append('new_password', UpdatePasswordRequest.new_password);

    if(UpdatePasswordRequest.onboarding_msg){
      data.append('onboarding_msg', UpdatePasswordRequest.onboarding_msg);
    }

    return new Promise<boolean>((resolve, reject) => {
      axios
        .post(url, data)
        .then((res: AxiosResponse<any>) => {
          if (200 === res.status) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            500 !== error.response.status &&
            404 !== error.response.status
          ) {
            console.log(error)
            resolve(false);
          } else {
            console.log(error)
            reject(error);
          }
        });
    });
  }

  signUp(signUpData: SignUpRequest): Promise<any> {
    const url: string = `${API_BASE_URL}/api/public/register-user/`;

    let data = new FormData();
    data.append('email', signUpData.email);
    data.append('first_name', signUpData.firstname);
    data.append('last_name', signUpData.lastname);
    data.append('password', signUpData.password);
    data.append('job_title', signUpData.jobTitle);
    data.append('timezone', signUpData.timezone);
	  data.append('terms_and_conditions', signUpData.termsAndConditions.toString());
    data.append('privacy_policy', signUpData.privacyPolicy.toString());
	  data.append('organisation_name', signUpData.companyName);

    return new Promise<boolean|number>((resolve, reject) => {
      axios
        .post(url, data)
        .then((res: AxiosResponse<any>) => {
          if (201 === res.status || 200 === res.status) {
            resolve(res.status);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            500 !== error.response.status &&
            404 !== error.response.status
          ) {
            console.log(error)
            resolve(false);
          } else {
            console.log(error)
            reject(error);
          }
        });
    });
  };

  validateToken(tokenToValidate: string): Promise<any> {
    const url: string = `${API_BASE_URL}/api/public/validate-token/${tokenToValidate}/`;

    return new Promise<boolean>((resolve, reject) => {
      axios
        .get(url)
        .then((res: AxiosResponse<any>) => {
          if (200 === res.status && res.data.data.is_valid === true) {
            resolve(res.data.data);
          } else {
            throw new Error('')
          }
        })
        .catch((error) => {
          if (
            error.response &&
            500 !== error.response.status &&
            404 !== error.response.status
          ) {
            reject(false);
          } else {
            reject(error);
          }
        });
    });
  }

  completeSignUp(token: string, userData: SignUpRequest): Promise<any> {
    const url: string = `${API_BASE_URL}/api/public/team-members/complete-signup/${token}/`;

    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    let data = {
      first_name: userData.firstname,
      last_name: userData.lastname,
      password: userData.password,
      job_title: userData.jobTitle,
      timezone: userData.timezone,
      terms_and_conditions: userData.termsAndConditions,
      privacy_policy: userData.privacyPolicy
    }

    return new Promise<boolean>((resolve, reject) => {
      axios
        .put(url, data, config)
        .then((res: AxiosResponse<any>) => {
          if(res.status === 200) {
            resolve(true)
          } else {
            throw new Error('')
          }
        })
        .catch((error) => {
          if(error.response && error.response.data){
            reject(error.response.data)
          } else {
            reject(false)
          }
        });
    });
  }

  validateAccount({confirmToken}: ConfirmToken): Promise<any> {
    const url: string = `${API_BASE_URL}/api/public/activate-account-user/?key=${confirmToken}`;

    return new Promise<boolean>((resolve, reject) => {
      axios
        .get(url)
        .then((res: AxiosResponse<any>) => {
          if (200 === res.status) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            500 !== error.response.status &&
            404 !== error.response.status
          ) {
            console.log(error)
            resolve(false);
          } else {
            console.log(error)
            reject(error);
          }
        });
    });
  }

  logout(): void {
    storageService.remove(LS_KEY_ACCESS_TOKEN);
  }

  getAccessToken(): string {
    return storageService.get(LS_KEY_ACCESS_TOKEN);
  }

  isAuthenticated(): boolean {
    return this.getAccessToken() ? true : false;
  }

  async refreshToken() {
    const url: string = `${API_BASE_URL}/api/token/refresh/`;
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/vnd.api+json",
      },
    };

    const data = {
      data: {
        type: "refreshJSONWebTokens",
        id: "",
        attributes: {
          token: this.getAccessToken()
        },
      },
    };

    try {
      let refreshToken = await axios.post(url, data, config);
      if (200 === refreshToken.status && refreshToken.data.data.token) {
        storageService.set(LS_KEY_ACCESS_TOKEN, refreshToken.data.data.token);
        return refreshToken.data.data;
      } else {
        return null;
      }
    } catch (error) {
	  this.logout();
	  CometChat.logout();
    }
  }
}

const authService = new AuthService();
export default authService;

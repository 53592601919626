import { createReducer } from "@reduxjs/toolkit";
import { userLoggout } from "../user/actions";
import { saveOrganizationData } from "./actions";

interface OrganizationState {
  organization: any;
}

const organizationState = {
  organization: [],
} as OrganizationState;

export const organizationReducer = createReducer(organizationState, (builder) =>
  builder
    .addCase(saveOrganizationData, (state, action) => {
      state.organization = action.payload;
    })
    .addCase(userLoggout.fulfilled, (state, action) => {
      state = organizationState;
    })
);

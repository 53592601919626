import { configureStore } from '@reduxjs/toolkit';
import { menuReducer } from './menu/reducer';
import { slackReducer } from './slack/reducer';
import { userReducer } from './user/reducer'
import { dashboardReducer } from './Dashboard/reducer'
import { teamReducer } from './team/reducer';
import { organizationReducer } from './organization/reducer';
import { surveyReducer } from './survey/reducer';
import { chatReducer } from './chat/reducer';
import { modalReducer } from './modal/reducer';

export const store = configureStore({
  reducer: {
    menuState: menuReducer,
    slackState: slackReducer,
    userState: userReducer,
    dashboardState: dashboardReducer,
    teamState: teamReducer,
    organizationState: organizationReducer,
    surveyState: surveyReducer,
    chatState: chatReducer,
    modalState: modalReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  })
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
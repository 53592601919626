import { createReducer } from "@reduxjs/toolkit";
import { userLoggout } from "../user/actions";
import { setSlackRedirectUrl } from "./actions";

interface SlackState {
  slackRedirectUrl: string;
}

const slackState = {
  slackRedirectUrl: "",
} as SlackState;

export const slackReducer = createReducer(slackState, (builder) =>
  builder
    .addCase(setSlackRedirectUrl, (state, action) => {
      state.slackRedirectUrl = action.payload;
    })
    .addCase(userLoggout.fulfilled, (state, action) => {
      state = slackState;
    })
);

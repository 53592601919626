import axios from "axios";
import { accountOwnerUpdateInterface } from "../interfaces/organizationInterfaces";
import storageService from "./Storage.service";

const API_URL = process.env.REACT_APP_URL_API;

export class OrganizationService {

  getOrganization() {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`
    }

    return new Promise<any>((resolve, reject) => {
      axios.get(API_URL + "/api/public/organisations/", {
        headers: headers
      }).then((res) => {
            resolve(res.data.data);
        })
        .catch((err) => {
            reject(err);
        });
    })
  };

  updateAccountOwner(organizationId: string, data: accountOwnerUpdateInterface) {
	const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`
    }

    return new Promise<any>((resolve, reject) => {
      axios.put(API_URL + `/api/public/organisations/${organizationId}/`, data, {
        headers: headers
      }).then((res) => {
            resolve(res.data.data);
        })
        .catch((err) => {
            reject(err);
        });
    })
  };
};

const organizationService = new OrganizationService();
export default organizationService;

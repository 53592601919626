import { createReducer } from "@reduxjs/toolkit";
import moment from "moment";
import { weeksInterface } from "../../interfaces/calendarInterfaces";
import { optInMemberInterface } from "../../interfaces/teamInterfaces";
import { userLoggout } from "../user/actions";
import {
  SaveWeeksData,
  SaveDayData,
  SaveEnergyLevelRecommendations,
  SaveTeamPowerRecommendations,
  saveTrackScores,
  saveScores,
  saveTeamScores,
  saveEnergyLevel,
  saveTeamEnergyLevel,
  saveOrgEnergyLevel,
  saveOrgScores,
  saveOrgDimensionsScores,
  saveTeamsDimensionsScores,
  saveUserDimensionsScores,
  saveMonthSelected,
  saveTeamPowerUserScores,
  saveTeamPowerTeamScores,
  saveTeamPowerOrgScores,
  saveTeamPowerUserDimmensionsScores,
  saveTeamPowerTeamsDimmensionsScores,
  saveTeamPowerOrgDimmensionsScores,
  fetchRecommendations,
  fetchAllTeamPowerData,
  saveTeamPowerDays,
  fetchOptInMembers,
} from "./actions";

interface DashboardState {
  start: any;
  end: any;
  day: any;
  energyLevelRecommendations: any;
  teamPowerRecommendations: any;
  trackScores: any;
  scores: number[];
  teamScores: number[];
  orgScores: number[];
  userEnergyLevel: number | string;
  teamEnergyLevel: number | string;
  orgEnergyLevel: number | string;
  orgDimensionsScores: any;
  teamsDimensionsScores: any;
  userDimensionsScores: any;
  monthSelected: any;
  teamPowerUserScores: any;
  teamPowerTeamsScores: any;
  teamPowerOrgScores: any;
  teamPowerUserDimmensionsScore: any;
  teamPowerTeamsDimmensionsScore: any;
  teamPowerOrgDimmensionsScore: any;
  loadingRecommendations: boolean;
  loadingTeamPowerData: boolean;
  teamPowerDays: weeksInterface[];
  optInData: {
    total_members: number;
    total_opt_in_members: number;
  };
  optInMembers: optInMemberInterface[];
  loadingOptInMembers: boolean;
}

const dashboardState = {
  start: moment().startOf("isoWeek"),
  end: moment().endOf("isoWeek"),
  day: null,
  loadingRecommendations: false,
  energyLevelRecommendations: [],
  teamPowerRecommendations: [],
  trackScores: [],
  scores: [0, 0, 0, 0, 0, 0, 0],
  teamScores: [0, 0, 0, 0, 0, 0, 0],
  orgScores: [0, 0, 0, 0, 0, 0, 0],
  userEnergyLevel: 0,
  teamEnergyLevel: 0,
  orgEnergyLevel: 0,
  orgDimensionsScores: [],
  teamsDimensionsScores: [],
  userDimensionsScores: [],
  monthSelected: moment(),
  teamPowerUserScores: [],
  teamPowerTeamsScores: [],
  teamPowerOrgScores: [],
  teamPowerUserDimmensionsScore: [],
  teamPowerTeamsDimmensionsScore: [],
  teamPowerOrgDimmensionsScore: [],
  loadingTeamPowerData: false,
  teamPowerDays: [],
  optInData: {
    total_members: 0,
    total_opt_in_members: 0,
  },
  optInMembers: [],
  loadingOptInMembers: false,
} as DashboardState;

export const dashboardReducer = createReducer(dashboardState, (builder) =>
  builder
    .addCase(SaveWeeksData, (state, action) => {
      state.start = action.payload.start;
      state.end = action.payload.end;
    })
    .addCase(SaveDayData, (state, action) => {
      state.day = action.payload;
    })
    .addCase(SaveEnergyLevelRecommendations, (state, action) => {
      state.energyLevelRecommendations = action.payload;
    })
    .addCase(SaveTeamPowerRecommendations, (state, action) => {
      state.teamPowerRecommendations = action.payload;
    })
    .addCase(saveTrackScores, (state, action) => {
      state.trackScores = action.payload;
    })
    .addCase(saveScores, (state, action) => {
      state.scores = action.payload;
    })
    .addCase(saveTeamScores, (state, action) => {
      state.teamScores = action.payload;
    })
    .addCase(saveEnergyLevel, (state, action) => {
      state.userEnergyLevel = action.payload;
    })
    .addCase(saveTeamEnergyLevel, (state, action) => {
      state.teamEnergyLevel = action.payload;
    })
    .addCase(saveOrgEnergyLevel, (state, action) => {
      state.orgEnergyLevel = action.payload;
    })
    .addCase(saveOrgScores, (state, action) => {
      state.orgScores = action.payload;
    })
    .addCase(saveOrgDimensionsScores, (state, action) => {
      state.orgDimensionsScores = action.payload;
    })
    .addCase(saveTeamsDimensionsScores, (state, action) => {
      state.teamsDimensionsScores = action.payload;
    })
    .addCase(saveUserDimensionsScores, (state, action) => {
      state.userDimensionsScores = action.payload;
    })
    .addCase(saveMonthSelected, (state, action) => {
      state.monthSelected = action.payload;
    })
    .addCase(saveTeamPowerUserScores, (state, action) => {
      state.teamPowerUserScores = action.payload;
    })
    .addCase(saveTeamPowerTeamScores, (state, action) => {
      state.teamPowerTeamsScores = action.payload;
    })
    .addCase(saveTeamPowerOrgScores, (state, action) => {
      state.teamPowerOrgScores = action.payload;
    })
    .addCase(saveTeamPowerUserDimmensionsScores, (state, action) => {
      state.teamPowerUserDimmensionsScore = action.payload;
    })
    .addCase(saveTeamPowerTeamsDimmensionsScores, (state, action) => {
      state.teamPowerTeamsDimmensionsScore = action.payload;
    })
    .addCase(saveTeamPowerOrgDimmensionsScores, (state, action) => {
      state.teamPowerOrgDimmensionsScore = action.payload;
    })
    .addCase(userLoggout.fulfilled, (state, action) => {
      state = dashboardState;
    })
    .addCase(fetchRecommendations.pending, (state) => {
      state.loadingRecommendations = true;
    })
    .addCase(fetchRecommendations.fulfilled, (state) => {
      state.loadingRecommendations = false;
    })
    .addCase(fetchRecommendations.rejected, (state) => {
      state.loadingRecommendations = false;
    })
    .addCase(fetchAllTeamPowerData.pending, (state) => {
      state.loadingTeamPowerData = true;
    })
    .addCase(fetchAllTeamPowerData.fulfilled, (state) => {
      state.loadingTeamPowerData = false;
    })
    .addCase(fetchAllTeamPowerData.rejected, (state) => {
      state.loadingTeamPowerData = false;
    })
    .addCase(saveTeamPowerDays, (state, action) => {
      state.teamPowerDays = action.payload;
    })
    .addCase(fetchOptInMembers.pending, (state, action) => {
      state.loadingOptInMembers = true;
    })
    .addCase(fetchOptInMembers.fulfilled, (state, action) => {
      state.loadingOptInMembers = false;
      state.optInData = action.payload.attributes;
      state.optInMembers = action.payload.attributes.members;
    })
    .addCase(fetchOptInMembers.rejected, (state, action) => {
      state.loadingOptInMembers = false;
    })
);

import React, { Suspense } from 'react';
import ReactDOM from "react-dom";
import { store } from './redux/store';
import { Provider } from 'react-redux';
import reportWebVitals from "./reportWebVitals";
import { CometChat } from "@cometchat-pro/chat";

var appID = process.env.REACT_APP_COMET_CHAT_APP_ID;
var region = process.env.REACT_APP_COMET_CHAT_REGION;

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

var appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
CometChat.init(appID, appSetting)
	.then(() => {
		ReactDOM.render(
			<React.StrictMode>
				<Provider store={store}>
					<Suspense fallback={<div />}>
						<App />
					</Suspense>
				</Provider>
			</React.StrictMode>,
		  document.getElementById("root")
		);
	},
	error => {
		console.log("Initialization failed with error:", error);
	}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export const SAVE_WEEKS_DATA = "SAVE_WEEKS_DATA";
export const SAVE_DAY = "SAVE_DAY";
export const SAVE_ENERGY_LEVEL_RECOMMENDATIONS = "SAVE_ENERGY_LEVEL_RECOMMENDATIONS";
export const SAVE_TEAM_POWER_RECOMMENDATIONS = "SAVE_TEAM_POWER_RECOMMENDATIONS";
export const SAVE_TRACK_SCORES = "SAVE_TRACK_SCORES";
export const SAVE_SCORES = "SAVE_SCORES";
export const SAVE_TEAM_SCORES = "SAVE_TEAM_SCORES";
export const SAVE_USER_ENERGY_LEVEL = "SAVE_USER_ENERGY_LEVEL";
export const SAVE_TEAM_ENERGY_LEVEL = "SAVE_TEAM_ENERGY_LEVEL";
export const SAVE_ORG_ENERGY_LEVEL = "SAVE_ORG_ENERGY_LEVEL";
export const SAVE_ORG_SCORES = "SAVE_ORG_SCORES";
export const SAVE_ORG_DIMENSIONS_SCORES = "SAVE_ORG_DIMENSIONS_SCORES";
export const SAVE_TEAMS_DIMENSIONS_SCORES = "SAVE_TEAMS_DIMENSIONS_SCORES";
export const SAVE_USER_DIMENSIONS_SCORES = "SAVE_USER_DIMENSIONS_SCORES";
export const SAVE_MONTH_SELECTED = "SAVE_MONTH_SELECTED";
export const SAVE_TEAM_POWER_USER_SCORES = "SAVE_TEAM_POWER_USER_SCORES";
export const SAVE_TEAM_POWER_TEAMS_SCORES = "SAVE_TEAM_POWER_TEAMS_SCORES";
export const SAVE_TEAM_POWER_ORG_SCORES = "SAVE_TEAM_POWER_ORG_SCORES";
export const SAVE_TEAM_POWER_USER_DIMMENSIONS_SCORES = "SAVE_TEAM_POWER_USER_DIMMENSIONS_SCORES";
export const SAVE_TEAM_POWER_TEAMS_DIMMENSIONS_SCORES = "SAVE_TEAM_POWER_TEAMS_DIMMENSIONS_SCORES";
export const SAVE_TEAM_POWER_ORG_DIMMENSIONS_SCORES = "SAVE_TEAM_POWER_ORG_DIMMENSIONS_SCORES";
export const FETCH_ENERGY_LEVEL_SCORES_DATA = "FETCH_ENERGY_LEVEL_SCORES_DATA";
export const CHECK_SURVEY_ANSWERED = "CHECK_SURVEY_ANSWERED";

import { createAction } from "@reduxjs/toolkit";
import {
  SAVE_USER_CHAT_NOTES,
  SAVE_USER_CHAT_PROFILE,
  SET_BOOK_SESSION_URL,
  SET_LOADING_URL,
} from "./constants";

export const saveUserChatProfile = createAction(
  SAVE_USER_CHAT_PROFILE,
  (payload: any) => ({
    payload: payload,
  })
);

export const saveUserChatNotes = createAction(
  SAVE_USER_CHAT_NOTES,
  (payload: any) => ({
    payload: payload,
  })
);

export const setBookSessionURL = createAction(
  SET_BOOK_SESSION_URL,
  (payload: any) => ({
    payload: payload,
  })
);

export const setLoadingURL = createAction(SET_LOADING_URL, (payload: any) => ({
  payload: payload,
}));

import { createReducer } from "@reduxjs/toolkit";
import { userLoggout } from "../user/actions";
import {
  SaveTeamData,
  saveMyTeamData,
  saveRolesList,
  setLoadingTeam,
  fetchOrganizationData,
  fetchTeamData,
} from "./actions";

interface TeamState {
  team: any;
  myDataTeam: any;
  myTeam: any;
  rolesList: any;
  loading: boolean;
  isOwner: boolean;
  isLeader: boolean;
  isBuyer: boolean;
  notInTeam: boolean;
  loadingOrganizationData: boolean;
  loadingTeamData: boolean;
}

const teamState = {
  team: [],
  myDataTeam: null,
  myTeam: [],
  rolesList: [],
  loading: true,
  isOwner: false,
  isLeader: false,
  isBuyer: false,
  notInTeam: false,
  loadingOrganizationData: false,
  loadingTeamData: false,
} as TeamState;

export const teamReducer = createReducer(teamState, (builder) =>
  builder
    .addCase(SaveTeamData, (state, action) => {
      state.team = action.payload.team;
      state.myDataTeam = action.payload.myDataTeam;
      state.myTeam = action.payload.myTeam;
      state.isLeader = action.payload.isLeader;
      state.isBuyer = action.payload.isBuyer;
    })
    .addCase(saveMyTeamData, (state, action) => {
      state.myTeam = action.payload;
    })
    .addCase(saveRolesList, (state, action) => {
      state.rolesList = action.payload;
    })
    .addCase(setLoadingTeam, (state, action) => {
      state.loading = action.payload;
    })
    .addCase(userLoggout.fulfilled, (state, action) => {
      state = teamState;
    })
    .addCase(fetchTeamData.pending, (state) => {
      state.loadingTeamData = true;
    })
    .addCase(fetchTeamData.fulfilled, (state) => {
      state.loadingTeamData = false;
    })
    .addCase(fetchTeamData.rejected, (state) => {
      state.loadingTeamData = false;
    })
    .addCase(fetchOrganizationData.pending, (state, action) => {
      state.loadingOrganizationData = true;
    })
    .addCase(fetchOrganizationData.fulfilled, (state, action) => {
      state.isOwner = action.payload.isOwner;
      state.loadingOrganizationData = false;
    })
    .addCase(fetchOrganizationData.rejected, (state, action) => {
      state.loadingOrganizationData = false;
    })
);

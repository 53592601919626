import axios, { AxiosAdapter } from "axios";
import { MessageRequest } from "../domain/request/chat-request";
import storageService from "./Storage.service";

const API_URL = process.env.REACT_APP_URL_API;

export class ChatService {

  getWorkspaces() {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`
    }

    axios.defaults.headers['Content-Type'] = 'application/vnd.api+json';

    return new Promise<any>((resolve, reject) => {
      axios.get(API_URL + "/api/chat/workspace-members/", {
        headers: headers
      }).then((res) => {
        resolve(res.data);
      })
        .catch((err) => {
          reject(err);
        });
    })
  }

  getSurveyScores(memberId: string, cancelToken: any, limit: number | null = null) {
    axios.defaults.headers['Content-Type'] = 'application/vnd.api+json';

    return new Promise<any>((resolve, reject) => {
      axios.get(`${API_URL}/api/chat/latest-scores/?member_id=${memberId}&limit=${limit}`, {
        cancelToken: cancelToken
      }).then((res) => {
        resolve(res.data);
      })
        .catch((err) => {
          reject(err);
        });
    })
  }

  getConversation(chatId: string, cancelToken: any) {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`
    }

    axios.defaults.headers['Content-Type'] = 'application/vnd.api+json';

    return new Promise<any>((resolve, reject) => {
      axios.get(API_URL + "/api/chat/conversations/?chat_id=" + chatId, {
        headers: headers,
        cancelToken: cancelToken
      }).then((res) => {
        resolve(res.data);
      })
        .catch((err) => {
          reject(err);
        });
    })
  }

  getConversationTags() {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`
    }

    axios.defaults.headers['Content-Type'] = 'application/vnd.api+json';

    return new Promise<any>((resolve, reject) => {
      axios.get(API_URL + "/api/chat/conversations-tags", {
        headers: headers
      }).then((res) => {
        resolve(res.data);
      })
        .catch((err) => {
          reject(err);
        });
    })
  }

  async saveTag(conversation_id: string, tagId: string, deleted: boolean) {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`,
      'Content-Type': 'application/json'
    }

    await axios.post(API_URL + `/api/chat/conversation/${conversation_id}/add-remove-tag/`, { tag_id: tagId, is_deleted: deleted }, {
      headers: headers
    });
  }

  async sendMessage(message: MessageRequest) {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`,
      'Content-Type': 'application/json'
    }

    await axios.post(API_URL + "/api/chat/send-message/", message, {
      headers: headers
    });
  }

  async sendEmail(channelId: string, cancelToken: any) {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`,
      'Content-Type': 'application/json'
    }

    return new Promise<any>((resolve, reject) => {
      axios.post(API_URL + "/api/chat/send-email/", { channel_id: channelId, type_email: 'energy_survey' }, {
        headers: headers,
        cancelToken: cancelToken
      }).then((res) => {
        resolve(res);
      })
        .catch((err) => {
          reject(err);
        });
    })
  }

  async getWeeksRecomendations() {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`,
      'Content-Type': 'application/json'
    }

    return new Promise<any>((resolve, reject) => {
      axios.get(API_URL + "/api/chat/send-email/", {
        headers: headers
      }).then((res) => {
        resolve(res);
      })
          .catch((err) => {
            reject(err);
          });
    })
  }

  async getUserChatProfile(uuid: string) {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`,
      'Content-Type': 'application/json'
    }

    return new Promise<any>((resolve, reject) => {
      axios.get(API_URL + `/api/public/users/${uuid}/chat-profile/`, {
    	headers: headers
	  })
	  	.then((res) => {
			if(res.status === 200){
				resolve(res.data);
			} else {
				throw new Error();
			}
    	})
        .catch((err) => {
        	reject(err);
        });
    })
  };

  async getUserNotes(uuid: string) {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`,
      'Content-Type': 'application/json'
    }

    return new Promise<any>((resolve, reject) => {
      axios.get(API_URL + `/api/public/users/${uuid}/text-notes/`, {
    	headers: headers
	  })
	  	.then((res) => {
			if(res.status === 200){
				resolve(res.data);
			} else {
				throw new Error();
			}
    	})
        .catch((err) => {
        	reject(err);
        });
    })
  };

  async saveUserNotes(data: any) {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`,
      'Content-Type': 'application/json'
    }

    return new Promise<any>((resolve, reject) => {
      axios.post(API_URL + `/api/public/text-notes/`, data, {
    	headers: headers
	  })
	  	.then((res) => {
			if(res.status === 201){
				resolve(res.data);
			} else {
				throw new Error();
			}
    	})
        .catch((err) => {
        	reject(err);
        });
    })
  };

  async updateUserNotes(data: any, id: any) {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`,
      'Content-Type': 'application/json'
    }

    return new Promise<any>((resolve, reject) => {
      axios.put(API_URL + `/api/public/text-notes/${id}/`, data, {
    	headers: headers
	  })
	  	.then((res) => {
			if(res.status === 200){
				resolve(res.data);
			} else {
				throw new Error();
			}
    	})
        .catch((err) => {
        	reject(err);
        });
    })
  };

  async sendAutomaticMessage(data: any) {
    const currentToken = storageService.get("access_token");
    const headers = {
      'Authorization': `jwt ${currentToken}`,
      'Content-Type': 'application/json'
    }

    return new Promise<any>((resolve, reject) => {
      axios.post(API_URL + `/api/chat/cometchat/send-messages/`, data, {
    	headers: headers
	  })
	  	.then((res) => {
        if(res.status === 200){
          resolve(res.data);
        } else {
          throw new Error();
        }
    	})
      .catch((err) => {
        reject(err);
      });
    })
  }
}

const chatService = new ChatService();
export default chatService;
